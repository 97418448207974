<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 90 91"
  >
    <path
      fill="currentColor"
      d="M45 0a45 45 0 1 0 0 90 45 45 0 0 0 0-90Zm0 7.6a37.4 37.4 0 0 1 34.7 51.8 37.3 37.3 0 0 1-49 20.4 37.3 37.3 0 0 1-20.4-49.1A37.3 37.3 0 0 1 45 7.6Zm-6 17.8c-7.3 0-13.6.7-19.7 2.6-1.6.3-2.9 1.5-2.9 3.7s1.6 4.2 3.9 3.9c1 0 1.6-.4 2.2-.4 5-1.2 10.8-1.8 16.5-1.8a63 63 0 0 1 30.1 6.9c1 .3 1.3.7 2.3.7 2.2 0 3.7-1.7 4-3.9 0-1.9-.9-3.1-2.2-3.7-9.5-5.4-22.1-8-34.2-8Zm-1 14c-6.7 0-11.4.9-16.2 2.2-1.6.6-2.5 1.6-2.5 3.5 0 1.6 1.3 3.1 3.2 3.1.6 0 1 0 1.9-.3 3.5-1 8.3-1.5 13.3-1.5 10.5 0 19.6 2.5 26.3 6.6.6.4 1.3.6 2.2.6 2 0 3.2-1.6 3.2-3.5 0-1.3-.6-2.5-1.9-3.2-8.3-5-18.7-7.6-29.5-7.6ZM39 53a58 58 0 0 0-15.6 2c-1.2.2-2.2 1.1-2.2 2.7 0 1.3 1 2.6 2.6 2.6.3 0 1.2-.3 1.9-.3 4.2-1 8.6-1.5 13-1.6 7.9 0 15.1 2 21.2 5.4.6.3 1.3.6 2 .6a3 3 0 0 0 2.8-2.2c0-1.6-.7-2.2-1.7-2.8-7-4.2-15-6.4-24-6.4Z"
    />
  </svg>
</template>
